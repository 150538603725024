.csku-styles.main-content {
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: inherit;
  background: #fff;
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding-left: 11rem;
  margin-top: 0rem !important; }

.csku-styles a {
  color: #02C0DA;
  text-decoration: none; }

@media (max-width: 640px) {
  .csku-styles.main-content {
    padding-left: 1px !important; } }

.react-datepicker-popper {
  z-index: 105; }
